// =============================================
// Mixin - Breakpoint - Based on M1's BP system
// =============================================

$mq-support: true !default;
$mq-fixed-value: 1024px !default;

@mixin bp($feature, $value) {
    // Set global device param
    $media: only screen;

    // Media queries supported
    @if $mq-support == true {

        @if $feature == "max-width" {
            $value: $value - 1;
        }

        @media #{$media} and ($feature: $value) {
            @content;
        }

        // Media queries not supported
    } @else {

        @if $feature == "min-width" {
            @if $value <= $mq-fixed-value {
                @content;
            }
        } @else if $feature == "max-width" {
            @if $value >= $mq-fixed-value {
                @content;
            }
        }

    }
}

//
// ----------------------------------------------
// Usage example:
// For IE set $mq-support to false.
// Set the fixed value.
// Then use mixins to test whether styles should be applied.
// ----------------------------------------------

// $mq-support: false;
// $mq-fixed-value: 1024;
//
// Renders at fixed value
// @include bp (min-width, 300px) {
//     div { color:#000; }
// }
//
// Doesn't render without MQ support
// @include bp (min-width, 1200px) {
//     div { color:#FFF; }
// }
//
// Doesn't render without MQ support
// @include bp (max-width, 300px) {
//     div { color:#444; }
// }
//
// Renders at fixed value
// @include bp (max-width, 1200px) {
//     div { color:#888; }
// }
//
// ----------------------------------------------
//

@mixin bpmm($min, $max) {
    $media: only screen;

    @if $mq-support == true {
        @if $max == max and $min != min {
            @include bp(min-width, $min) {
                @content;
            }
        } @else if $min == min and $max != max {
            @include bp(max-width, $max) {
                @content;
            }
        } @else if $min == min and $max == max {
            @content;
        } @else {
            @media #{$media} and (min-width: $min) and (max-width: $max - 1) {
                @content;
            }
        }
    } @else {
        @if $min >= $mq-fixed-value and $max <= $mq-fixed-value {
            @content;
        }
    }
}
