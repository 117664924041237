// =============================================
// Compass Defaults
// =============================================

// Border Radius

$default-border-radius: 5px;

// Transition

$default-transition-property: all;
$default-transition-duration: 200ms;
$default-transition-function: ease;
$default-transition-delay: 0;

$hover-transition-duration: 200ms;
$hover-transition-function: ease;

$navigation-transition-duration: 250ms;
$navigation-transition-function: cubic-bezier(0.645, 0.045, 0.355, 1);

// =============================================
// Primary Break Points
// =============================================

// These should be used with the bp(max-width, $bp-xx) or bp(min-width, $bp-xx) mixin.
// If these are changed, they must also be updated in global-vars.js

$bp-small: 576px;
$bp-medium: 768px;
$bp-large: 1024px;
$bp-xlarge: 1200px;
$bp-xxlarge: 1360px;

$bp-custom-small: 480px;
$bp-custom-large: 1127px;
$bp-custom-xlarge: 1360px;
$bp-custom-xxlarge: 1920px;


// Grayscale
$white: #fff;
$black: #000;
$black-5: darken($white, 5%);
$black-10: darken($white, 10%);
$black-20: darken($white, 20%);
$black-30: darken($white, 30%);
$black-40: darken($white, 40%);
$black-50: darken($white, 50%);
$black-60: darken($white, 60%);
$black-70: darken($white, 70%);
$black-80: darken($white, 80%);
$black-90: darken($white, 90%);
$black: #000;

// =============================================
// Typography
// =============================================

// Font Stacks

$f-stack-icon: "SSStandard";
$f-future-book: "FuturaBT-Book", Arial;
$f-classical: "ClassicalGaramondBT-Italic", serif;
$f-classical-normal: "ClassicalGaramondBT-Roman", serif;
$f-neue: "NeueHaasUnicaPro-Regular", Arial;
$f-neue-medium: "NeueHaasUnicaPro-Medium", Arial;
$f-neue-bold: "NeueHaasUnicaPro-Bold", Arial;
$f-neue-med: "NeueHaasUnicaPro-Medium", "NeueHaasUnicaPro-Regular", Arial;
$f-icon: "icomoon";
$f-avenir: "Avenir";

$f-primary: $f-avenir;

// Icon Entities
$icon-button-play: "\e906"; // play button
$icon-button-right-arrow: "\e907"; // right arrow
$icon-button-left-arrow: "\e919";
$icon-button-up-arrow: "\e91c";
$icon-select-down-arrow: "\e905"; // select down arrow
$icon-cart-buggie: "\e900"; // cart icon
$icon-favs-heart: "\e901"; // favorites heart
$icon-menu: "\e902";
$icon-search: "\e903";
$icon-store-locator-marker: "\e904"; // Search icon
$icon-close-x: "\e908"; // x close icon
$icon-registries: "\e909";
$icon-blockquote: "\e90a";
$icon-zoom-expand: "\e90b";
$icon-carousel-next: "\e90c";
$icon-carousel-next-large: "\e91a";
$icon-carousel-prev: "\e90d";
$icon-carousel-prev-large: "\e91b";
$icon-facebook: "\e90e";
$icon-instagram: "\e90f";
$icon-pinterest: "\e910";
$icon-twitter: "\e911";
$icon-check: "\e912";
$icon-close-bulky-x: "\e913"; // x close icon - bulky
$icon-pagination-first: "\e914";
$icon-pagination-last: "\e915";
$icon-pagination-next: "\e916";
$icon-pagination-prev: "\e917";
$icon-ethos: "\e921"; // "Our Ethos" icon
$icon-compare: "\e918";
$icon-qty-plus: "\ea0a";
$icon-qty-minus: "\ea0b";
$icon-careinfo: "\e91d";
$icon-tearsheet: "\e91e";
$icon-mailto: "\e91f";
$icon-login: "\e920";
$icon-addtoproject: "\e923"; // Add to Project Icon

// =============================================
// Brand Colors
// =============================================

// Usually not used directly in Sass partials.
// Only used to define context-based color vars in this file.

$c-blue: #39c;
$c-green: #267334;
$c-pink: #d85378;
$c-orange: #f3793b;
$c-red: #cf5050;
$c-yellow: #ffdd15;
$c-black: #000;
$c-gray: #333;
$b-gray: #222;

$brand-primary-dark: #2e2d2a;
$brand-primary-light: #54584e;
$brand-primary: $brand-primary-dark;
$brand-orange: #fc862a;
$brand-charcoal: #585755;
$brand-grey: #767679;
$brand-aaa-grey: #585856;
$brand-light-grey: #d5d5d4;
$brand-light-grey-2: #e7e7e7;
$brand-section-grey: #f1f1f1;



$brand-secondary: #54584e;
$brand-success: $c-green;
$brand-tertiary: $c-pink;
$brand-warning: $c-orange;
$brand-danger: $c-red;
$brand-info: lighten($brand-warning, 10%);
$brand-dark: darken($brand-primary, 15%);
$brand-light: lighten($brand-primary, 15%);

// =============================================
// Base Colors
// =============================================

// Text

$c-text: $brand-primary-light;
$brand-text: $c-text;
$c-text-gray: $black-50;
$c-text-white: $black-10;
// Primary font color for headings and other non-link text
$c-text-primary: $brand-primary;

// Interaction

$c-action: $brand-primary;
$c-stimulus: darken($brand-primary, 15%);
$c-subtle: $black-10;

// Notifications

$c-danger: $brand-danger;
$c-danger-background: $white;

$c-success: $c-green;
$c-success-background: $white;

$c-warn: $brand-warning;
$c-warn-background: $white;

$c-dark: $brand-dark;

$c-error: #fc442a;
$c-error-background: #ffb1a6;

// Global Elements

$c-divider-border: $black-10;

// Form Elements

$field-border: #ccc;
$field-border-error: #fc442a;
$field-border-success: #5d8a5e;

$c-input-border: #c0c0c0;
$c-input-border-focus: $c-blue;

$c-input-placeholder: $c-text-gray;
$c-input-text: $c-text;

$c-fieldset-border: #ccc;
$c-fieldset-border-light: #ededed;

// Headings

$c-h1: $c-blue;
$c-h2: $c-text;
$c-h3: $c-text;
$c-h4: $c-text;
$c-h5: $c-text;
$c-h6: $c-text;

// Link
$c-link: $c-action;
$c-link-hover: darken($c-action, 5%);
$c-link-focus: $c-action;
$c-link-active: darken($c-action, (5% / 2));

// Module (generic)

$c-module-background: #f4f4f4;
$c-module-background-hover: shade($c-module-background, 3%);
$c-module-background-secondary: #eaf8ff;
$c-module-background-dark: #aaa;
// This should be applied whenever the border is surrounding a white element and/or is directly showing on white.
$c-module-border: #ccc;
// This should be applied whenever the border is wrapping an element with $c-module-background
$c-module-border-light: #ededed;
$c-module-border-dark: #777;
$c-module-border-highlight: $c-blue;

// Table

$c-table-background: #f4f4f4;
$c-table-border: #c0c0c0;

$c-table-zebra-odd: #f8f7f5;
$c-table-zebra-even: #eeeded;

// Navigation
$white-smoke: #f7f7f7;

// Tabs

$c-tabs-background: #f0f0f0;

// Email
$c-email-background: #ebebeb;
$c-email-background-secondary: #e1f0f8;
$c-email-border: #c3ced4;
$c-email-action: #3696c2;
$c-email-cell: #f1f1f1;
$c-email-highlighted-background: #fffdd9;
$c-email-highlighted-border: #fff74c;

// Product
$product-column-spacing: 20px;
$accordion-border: 1px solid $very-light-gray;
$heading-border: 1px solid $butterfly-ash;

// Checkout
$checkout-border: 1px solid $very-light-gray;

// Buttons
$filter-black-to-coal: invert(26%) sepia(7%) saturate(0%) hue-rotate(144deg) brightness(93%) contrast(86%);
$filter-black-to-black-two: invert(14%) sepia(6%) saturate(539%) hue-rotate(7deg) brightness(96%) contrast(90%);
$filter-black-to-white: invert(99%) sepia(1%) saturate(526%) hue-rotate(352deg) brightness(115%) contrast(100%);
$filter-black-to-dark-sage: invert(34%) sepia(11%) saturate(350%) hue-rotate(42deg) brightness(93%) contrast(89%);

// Font Sizes

$f-size-xxl: 18px;
$f-size-xl: 16px;
$f-size-l: 15px;

$f-size: 14px;

$f-size-s: 13px;
$f-size-xs: 12px;
$f-size-xxs: 11px;

$header-arrows-font-size: 14px;

// Base Measures

$b-line-height: 1.5;
$b-margin-bottom: 1.5em;

//native swatch background variables

$swatch-types: black #000, beige #f5f5dc, blue blue, purple purple, red red, brown #783201, green green, grey #8f979d, pink #fe249a, orange orange, white #fff, yellow #ff0, navy navy;
//
// Mixins: vendor prefixes
// --------------------------------------------------

// Box sizing
@mixin box-sizing($box-model) {
    box-sizing: $box-model;
}

// Single side border-radius
@mixin border-top-radius($radius) {
    border-top-right-radius: $radius;
    border-top-left-radius: $radius;
}

@mixin border-right-radius($radius) {
    border-bottom-right-radius: $radius;
    border-top-right-radius: $radius;
}

@mixin border-bottom-radius($radius) {
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
}

@mixin border-left-radius($radius) {
    border-bottom-left-radius: $radius;
    border-top-left-radius: $radius;
}

// Drop shadows
@mixin box-shadow($shadow) {
    box-shadow: $shadow;
}

// Transitions
@mixin transition($transition) {
    transition: $transition;
}

@mixin transition-delay($transition-delay) {
    transition-delay: $transition-delay;
}

@mixin transition-duration($transition-duration) {
    transition-duration: $transition-duration;
}

// Transformations
@mixin rotate($degrees) {
    transform: rotate($degrees);
}

@mixin scale($ratio) {
    transform: scale($ratio);
}

@mixin translate($x, $y) {
    transform: translate($x, $y);
}

@mixin skew($x, $y) {
    transform: skew($x, $y);
    @include backface-visibility(hidden);
}

@mixin translate3d($x, $y, $z) {
    transform: translate3d($x, $y, $z);
}

// Backface visibility
@mixin backface-visibility($visibility) {
    backface-visibility: $visibility;
}

// User select
@mixin user-select($select) {
    user-select: $select;
}

// Opacity
@mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$opacity-ie})";
}

// Placeholder text
@mixin placeholder() {
    &::-moz-placeholder {
        @content;
    }
    // Firefox 19+
    &:-ms-input-placeholder {
        @content;
    }
    // Internet Explorer 10+
    &::-webkit-input-placeholder {
        @content;
    }
}

// PDP Accordions
%accordion-font {
    font: normal normal 14px/20px $f-neue;
    letter-spacing: 0;
    color: $dark-sage;
}

%accordion-header-font {
    font: normal normal 12px/48px $f-neue-bold;
    letter-spacing: 0;
    color: $dark-sage;
}

// PDP Carousels
%carousel-header {
    font: normal bold 30px/48px $f-primary;
    letter-spacing: 2px;
    color: $black-two;
}

%pdp-tile-name {
    font: normal normal 24px/29px $f-primary;
    letter-spacing: 1px;
    color: $black-two;
}

%pdp-tile-price {
    font: normal normal 13px/23px $f-neue;
    letter-spacing: 0.5px;
    color: $dark-sage;
}

%pdp-tile-club-price {
    font: normal bold 13px/23px $f-neue;
    letter-spacing: 0.5px;
    color: $black-two;
}

%pdp-tile-add-to-cart {
    font: normal bold 11px/15px $f-neue;
    letter-spacing: 1.2px;
    color: $black-two;
    text-decoration: underline;
}

@mixin pdp-tile-border() {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 71%;
    display: block;
    content: "";
    border-top: $heading-border;
}

%checkout-section-heading {
    font: normal bold 14px/18px $f-neue;
    letter-spacing: 0.5px;
    color: $black-two;
}

%checkout-text {
    font: normal normal 14px/22px $f-neue;
    color: $dark-sage;
}

%icon-tooltip {
    border: 1px solid $brand-v2-off-black;
    border-radius: 50%;
    font: 800 normal 16px/18px $f-avenir;
    height: 20px;
    text-align: center;
    text-decoration: none;
    width: 20px;
}

// Resize anything
@mixin resizable($direction) {
    resize: $direction; // Options: horizontal, vertical, both
    overflow: auto; // Safari fix
}

// CSS3 Content Columns
@mixin content-columns($width, $count, $gap) {
    column-width: $width;
    column-count: $count;
    column-gap: $gap;
}

// Optional hyphenation
@mixin hyphens($mode: auto) {
    word-wrap: break-word;
    hyphens: $mode;
}

// Gradients
@mixin gradient-horizontal($startColor: #555, $endColor: #333) {
    background-color: $endColor;
    background-image: linear-gradient(to right, $startColor, $endColor); // Standard, IE10
    background-repeat: repeat-x;
    filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#{argb($startColor)}', endColorstr='#{argb($endColor)}', GradientType=1)"; // IE9 and down
}

@mixin gradient-vertical($startColor: #555, $endColor: #333) {
    background-color: $endColor;
    background-image: linear-gradient(to bottom, $startColor, $endColor); // Standard, IE10
    background-repeat: repeat-x;
    filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#{argb($startColor)}', endColorstr='#{argb($endColor)}', GradientType=0)"; // IE9 and down
}

// Reset filters for IE
//
// Useful for when you want to remove a gradient from an element.
@mixin reset-filter() {
    filter: "progid:DXImageTransform.Microsoft.gradient(enabled = false)";
}

//
// Mixins: utilities
// --------------------------------------------------
@mixin clearfix {
    &::before,
    &::after {
        content: " "; // 1
        display: table; // 2
    }
    &::after {
        clear: both;
    }
}

// Center-align a block level element
@mixin center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// Sizing shortcuts
@mixin size($width, $height) {
    width: $width;
    height: $height;
}

@mixin square($size) {
    @include size($size, $size);
}

// Text overflow
//
// Requires inline-block or block for proper styling
@mixin text-truncate() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// Retina images
//
// Retina background-image support with non-retina fall back
@mixin retina-image($file-1x, $file-2x, $width-1x, $height-1x) {
    background-image: url("${file-1x}");

    @media only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min--moz-device-pixel-ratio: 2),
        only screen and (-o-min-device-pixel-ratio: 2 / 1),
        only screen and (min-device-pixel-ratio: 2),
        only screen and (min-resolution: 192dpi),
        only screen and (min-resolution: 2dppx) {
            background: url("${file-2x}");
            background-size: $width-1x $height-1x;
    }
}

@mixin sprite-icon($xpos, $ypos, $width, $height) {
    background: url("../images/blueacorn/icon_sprite.png") no-repeat $xpos $ypos;
    height: $height;
    width: $width;

    @media only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min--moz-device-pixel-ratio: 2),
        only screen and (-o-min-device-pixel-ratio: 2 / 1),
        only screen and (min-device-pixel-ratio: 2),
        only screen and (min-resolution: 192dpi),
        only screen and (min-resolution: 2dppx) {
            background-image: url("../images/blueacorn/icon_sprite@2x.png");
            background-size: 794px 332px;
    }
}

// Used to create a centered responsive box with a maximum width
@mixin max-width($max-width) {
    width: 100%;
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
}

// quick & easy link stying
@mixin link($color, $hover: $color) {
    @include transition(all, $hover-transition-duration, $hover-transition-function);
    color: $color;

    &:hover {
        color: $hover;
    }
}

// custom bottom border mixin
@mixin bottom-border($color: $brand-primary-dark) {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    padding-bottom: 24px;

    &::after {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 50%;
        margin-left: -25%;
        content: "";
        display: block;
        border-bottom: 1px solid $color;
    }
}

@mixin collapse-box {
    display: block;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    transition: max-height $navigation-transition-duration $navigation-transition-function;

    &:first-child {
        margin-top: 0;
    }

    &.active {
        > h3::before {
            transform: rotateX(180deg);
        }
    }

    > h3 {
        @extend %collapse-header;
    }

    > ul,
    > .ul {
        padding: 8px 10px 24px;
        position: relative;
        margin: 0;
    }

    label.radio-label,
    label.checkbox-label,
    span.label.radio-label,
    span.label.checkbox-label {
        font-size: 14px;

        &::before {
            top: 0;
            width: 16px;
            height: 16px;
        }
    }
}

// ============================================ *
// Blur backdrop effect
// ============================================ */

@mixin blur-backdrop($blurAmt: 9px) {
    -webkit-backdrop-filter: saturate(100%) blur($blurAmt);
    backdrop-filter: saturate(100%) blur($blurAmt);
}

/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
    overflow: hidden;
    position: relative;
    line-height: $lineHeight;
    max-height: $lineHeight * $lineCount;
    text-align: justify;
    margin-right: -16px;
    padding-right: 16px;

    &::before {
        content: "...";
        position: absolute;
        right: 0;
        bottom: 0;
    }

    &::after {
        content: "";
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        background: $bgColor;
    }
}
