@import "../../../../ba_gp_storefront_core/cartridge/scss/default/mixin/breakpoint";
@import "../../../../ba_gp_storefront_core/cartridge/scss/default/variables";
@import "../../../../ba_gp_storefront_core/cartridge/scss/default/blueacorn/var";
@import "components/variables";
@import "components/mixins";

$ex: ex-static-2-3-zig-zag;

.#{$ex} {
    &__inner {
        color: $black;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 1640px;
        padding: 24px 20px;

        @include bp(min-width, $bp-medium) {
            flex-direction: row;
            padding-left: 16px;
            padding-right: 16px;
        }

        @include bp(min-width, $bp-large) {
            padding: 48px 20px;
        }

        &.image-right {
            @include bp(min-width, $bp-medium) {
                flex-direction: row-reverse;
            }

            .#{$ex} {
                &__column__text {
                    @include bp(min-width, $bp-medium) {
                        margin-left: 0;
                        margin-right: 20px;
                    }
                }
            }
        }
    }

    &__column {
        flex: 1;

        &__video {
            width: 100%;
            position: relative;
            padding-top: var(--aspect_ratio_mobile);
            overflow: hidden;

            @include bp(min-width, $bp-medium) {
                padding-top: var(--aspect_ratio_desktop);
            }
        }

        &__text {
            align-items: center;
            align-self: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 520px;
            margin-top: 20px;
            text-align: center;

            @include bp(min-width, $bp-medium) {
                margin-left: 20px;
                margin-top: 0;
            }
        }
    }

    &__video-link,
    iframe {
        border: 0;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__headline {
        margin-bottom: 12px;
    }

    &__body-copy {
        margin-bottom: 12px;

        @include bp(min-width, $bp-large) {
            margin-bottom: 24px;
        }
    }

    &__mobile-only {
        @include bp(min-width, $bp-medium) {
            display: none;
        }
    }

    &__desktop-only {
        @include bp(max-width, $bp-medium) {
            display: none;
        }
    }

    img {
        width: 100%;
    }
}

// Override global styles
.page-designer-reference {
    .#{$ex} {
        &__headline,
        &__body-copy {
            > * {
                margin: 0;
            }
        }

        &__cta {
            color: inherit;

            &:active,
            &:focus,
            &:hover {
                color: $white;
                text-decoration: none;
            }
        }
    }

    .cta-as-link {
        border: none;
        font-weight: 600;
        background-color: transparent;
        color: black;
        padding: 0;

        &:hover {
            color: black;
        }
    }

    .ex-static-2-3-zig-zag__headline {
        h3 {
            text-transform: none;
        }
    }
}

.primary-content {
    .page-designer-reference {
        .#{$ex} {
            h2 {
                text-transform: none;
            }
        }
    }
}
