@import "../../../../../ba_gp_storefront_core/cartridge/scss/default/blueacorn/var";
@import "../../../../../ba_gp_storefront_core/cartridge/scss/default/mixin/breakpoint";

@mixin button($border: 1px solid $black, $color: $black, $background: transparent, $hover-bg: $black, $hover-color: $white, $font: 12px/16px $f-primary, $padding: 12px 30px) {
    border: $border;
    color: $color;
    background: $background;
    padding: $padding;
    text-transform: none;
    min-width: 136px;
    height: auto;
    font-family: $f-primary;

    &:hover {
        background: $hover-bg;
        color: $hover-color;
        text-decoration: none;
    }
}

@mixin plus-minus($border: 1px solid $black, $color: $black, $outline: $white, $background: transparent) {
    background-color: $background;
    border: $border;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    overflow: visible;
    height: 25px;
    padding: 0;
    width: 25px;

    &:hover {
        background-color: $color;

        &::before,
        &::after {
            border-color: $outline;
        }
    }

    &:focus {
        outline: none;
    }

    &::before {
        border-top: 1px $color solid;
        content: " ";
        display: inline-block;
        height: 1px;
        left: 6px;
        position: absolute;
        top: 11px;
        width: 11px;
    }

    &::after {
        border-left: 1px $color solid;
        content: " ";
        display: inline-block;
        height: 11px;
        left: 11px;
        position: absolute;
        top: 6px;
        width: 1px;
    }
}

@mixin empty-container() {
    margin: 0 !important;
    padding: 0 !important;
}

@mixin button-link($color: $black, $border-color: $color, $color-hover: $color, $border-color-hover: transparent, $spacing: normal, $font: normal normal 12px/18px $f-primary) {
    border-bottom: 1px $border-color solid;
    color: $color;
    font: $font;
    letter-spacing: $spacing;

    &:hover,
    &:focus,
    &.active {
        border-color: $border-color-hover;
        color: $color-hover;
        text-decoration: none;
    }
}

@mixin carousel-arrow ($side: "prev") {
    background: transparent;
    color: $black;
    font-size: 0;
    margin: 0;
    padding: 0;
    position: relative;
    width: 35px;

    &::before,
    &::after {
        content: " ";
        display: inline-block;
        position: absolute;
        right: 0;
    }

    @if $side == "next" {
        &::before {
            border-top: 1px $black solid;
            top: 12px;
            width: 35px;
        }

        &::after {
            border-right: 1px solid $black;
            border-bottom: 1px solid $black;
            width: 11px;
            height: 11px;
            transform: rotate(-45deg);
            top: 7px;
        }
    }

    @if $side == "prev" {
        &::before {
            border-right: 1px solid $black;
            border-bottom: 1px solid $black;
            width: 11px;
            height: 11px;
            left: 0;
            right: auto;
            transform: rotate(135deg);
            top: 7px;
        }

        &::after {
            border-top: 1px $black solid;
            top: 12px;
            width: 35px;
        }
    }
}

@mixin carousel-arrow-pd ($side: "prev") {
    background: transparent;
    color: $black;
    font-size: 0;
    margin: 0;
    padding: 0;
    position: relative;
    width: 37px;

    &::before,
    &::after {
        content: " ";
        display: inline-block;
        position: absolute;
        right: 0;
    }

    @if $side == "next" {
        &::before {
            border-top: 1px $black solid;
            top: 17px;
            width: 37px;
        }

        &::after {
            border-right: 1px solid $black;
            border-bottom: 1px solid $black;
            width: 21px;
            height: 21px;
            right: 2px;
            transform: rotate(-45deg);
            top: 7px;
        }
    }

    @if $side == "prev" {
        &::before {
            border-right: 1px solid $black;
            border-bottom: 1px solid $black;
            width: 21px;
            height: 21px;
            left: 2px;
            right: auto;
            transform: rotate(135deg);
            top: 7px;
        }

        &::after {
            border-top: 1px $black solid;
            top: 17px;
            width: 37px;
        }
    }
}

@mixin slider-counter ($color: $black) {
    bottom: 1px;
    color: $color;
    font: normal normal 16px/18px $f-classical-normal;
    position: absolute;
    right: 31px;

    @include bp(min-width, $bp-large) {
        margin-right: -15px;
        right: 50%;
    }
}

@mixin typography(
    $m-size: null,
    $m-line: null,
    $m-weight: null,
    $t-size: null,
    $t-line: null,
    $t-weight: null,
    $size: null,
    $line: null,
    $weight: null,
    $letter: null
) {

    @if $m-weight != null {
        font-weight: $m-weight;
    }
    @include bp(min-width, $bp-medium) {
        @if $t-weight != null {
            font-weight: $t-weight;
        }
    }
    @include bp(min-width, $bp-custom-large) {
        @if $weight != null {
            font-weight: $weight;
        }
    }

    @if $m-size != null {
        font-size: $m-size !important;
        line-height: $m-line !important;
    }
    @include bp(min-width, $bp-medium) {
        @if $t-size != null {
            font-size: $t-size !important;
            line-height: $t-line !important;
        }
    }
    @include bp(min-width, $bp-custom-large) {
        @if $size != null {
            font-size: $size !important;
            line-height: $line !important;
        }
    }
}

//Spacing mixin is equal to bootstrap spacers but with 'pd' prefix. Usage: mt-pd-1 p-pd-0 pb-md-pd-1. One step is equal to 8px;
@mixin pd-spacing() {
    @each $space, $length in $spacers {
        .m-pd-#{$space} {
            margin: #{$length};
        }

        .m-md-pd-#{$space} {
            @include bp(min-width, $bp-medium) {
                margin: #{$length} !important;
            }
        }

        .p-pd-#{$space} {
            padding: #{$length};
        }

        .p-md-pd-#{$space} {
            @include bp(min-width, $bp-medium) {
                padding: #{$length} !important;
            }
        }
        @each $side in $sides-pd {
            .m#{str-slice($side, 0, 1)}-md-pd-#{$space} {
                @include bp(min-width, $bp-medium) {
                    margin-#{$side}: #{$length} !important;
                }
            }
            .m#{str-slice($side, 0, 1)}-pd-#{$space} {
                margin-#{$side}: #{$length};
            }

            .p#{str-slice($side, 0, 1)}-md-pd-#{$space} {
                @include bp(min-width, $bp-medium) {
                    padding-#{$side}: #{$length} !important;
                }
            }
            .p#{str-slice($side, 0, 1)}-pd-#{$space} {
                padding-#{$side}: #{$length};
            }

        }

        // negative margins
        .m-pd-n#{$space} {
            margin: -#{$length} !important;
        }

        .m-md-pd-n#{$space} {
            @include bp(min-width, $bp-medium) {
                margin: -#{$length} !important;
            }
        }

        @each $side in $sides-pd {
            .m#{str-slice($side, 0, 1)}-md-pd-n#{$space} {
                @include bp(min-width, $bp-medium) {
                    margin-#{$side}: -#{$length} !important;
                }
            }
            .m#{str-slice($side, 0, 1)}-pd-n#{$space} {
                margin-#{$side}: -#{$length} !important;
            }
        }
    }
}

@mixin button-play($color: $black, $size: 50px) {
    width: $size;
    height: $size;
    border-style: solid;
    box-sizing: border-box;
    border-width: ($size / 2) 0 ($size / 2) $size;
    background-color: transparent;
    border-color: transparent transparent transparent $color;
    padding: 0;
    opacity: 0.7;

    &:focus {
        outline: none;
    }

    &:hover,
    &:focus {
        opacity: 1;
    }
}

.page-designer-reference {
    @include pd-spacing();
}
