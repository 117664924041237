// mixins
%clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

.clearfix {
    @extend %clearfix;
}

// colors
// names taken from http://www.color-blindness.com/color-name-hue/
// or https://chir.ag/projects/name-that-color
$alabaster: #fafafa;
$black: #000;
$cardinal: #bf242b;
$cerulean: #00629b;
$charcoal: #424242;
$charcoal-gray: #434343;
$citrus: #39c;
$crown-of-thorns: #7f2025;
$dark-gray: #aaa;
$dim-gray: #666;
$gainsboro: #e0e0e0;
$gallery: #eaeaea;
$islamic-green: #019328;
$limed-ash: #73766f;
$night-rider: #333;
$nobel: #999;
$pacifika: #837425;
$quarter-pearl-lusta: #fffdf5;
$rain-forest: #39c;
$sangria: #900;
$stark-white: #cebdb3;
$teal: #008578;
$tutu: #faeded;
$very-light-gray: #ccc;
$very-light-gray-v2: #bebebe;
$very-middle-gray: #c4c4c4;
$very-middle-gray-v2: #c9c9c9;
$very-middle-gray-v3: #e3e3e3;
$very-middle-gray-v4: #eee;
$very-middle-gray-v5: #ababab;
$very-middle-gray-v7: #e1e1e1;
$very-middle-gray-v8: #b5b5b5;
$very-middle-gray-v9: #a5a5a5;
$very-middle-gray-v10: #d3d3d3;
$very-middle-gray-v11: #838383;
$white-smoke: #efefef;
$white: #fff;
$dark-sage: #54584e;
$black-two: #2e2d2a;
$orange: #fd7020;
$add-to-cart-black: #292825;
$butterfly-ash: #979797;
$pewter: #f3f3f3;
$light-smoke: #f1f1f1;
$bokara-gray: #8f8d8b;
$coal: #464646;
$another-gray: #d8d8d8;
$crumb-gray: #73766f;
$hot-hands: #ae1e1e;
$boulder: #f7f7f7;
$faded-stone: #73766f;
$burnt-ochre: #bd5300;
$email-text: #929396;
$silver: #cdcdcd;
$scorpion: #595959;
$night: #34382e;
$brown: #8b6e38;
$smoky: #a1acad;
$california-gold: #b89c37;
$alto: #d9d9d9;
$goblin: #357a41;

$brand-v2-off-black: #222;
$brand-v2-dark-gray: $scorpion;
$brand-v2-med-gray: #737373;
$brand-v2-medlt-gray: $very-middle-gray;
$brand-v2-lt-gray: #e7e7e7;
$brand-v2-white: #fcfcfb;
$brand-v2-off-white: #fcfbfa;
$brand-v2-pink-cream: #faf8f7;
$brand-v2-pink: #f6dfd3;
$brand-v2-red: #e96565;

// MGBW StyleGuide Feb 03,2023
$clr_brand_black: $black;
$clr_white: $white;
$clr_dark_mid: $scorpion;
$clr_light_mid: $light-smoke;
$clr_off_white: $alabaster;
$clr_light_gold: $quarter-pearl-lusta;
$clr_notification_error: $cardinal;
$clr_sale: $crown-of-thorns;
$clr_input_notactive: $very-light-gray;
$clr_gold_membership_light: $california-gold;
$clr_gold_membership_dark: $pacifika;

$carousel-background: #f6f6f6;
$cc-background: $quarter-pearl-lusta;
$cart-table-title-top-border-color: #696969;
$cart-table-title-bottom-border-color: #818181;
$carousel-dots-background: #dbdbdb;
$carousel-dots-active-background: $black;
$vertical-separator-background: #c5c5c5;
$promo-price-color: #c0615a;
$promo-price-color-v2: #a02d27;
$collection-bg: #fbfbfb;

$pdp-v2-customization-tool-selected-background: #f9f7f6;
$pdp-v2-customization-tool-filter-border-color: #c1c1c1;
$pdp-v2-customization-tool-back-arrow-color: #717171;
$pdp-v2-customization-tool-button-border-color: #e3e3e3;
$pdp-v2-customization-tool-button-border-color-selected: #303030;
$pdp-v2-customization-tool-close-modal-cross-background: #989898;
$pdp-v2-customization-tool-separator-color: #d0d0d0;
$pdp-v2-sticky-bottom-section-background: #f9f7f6;

$design-services-input-border-color: #343434;
$design-services-input-label-color: #606060;
$design-services-checkbox-border-color: #bababa;

$search-refinements-background: $alabaster;
$search-refinements-border-color: $gallery;
$search-refinements-divide-border-color: #e1e1e1;
$breadcrumb-refinement-value-border-color: $clr_dark_mid;

$tile-image-background: rgba(223, 223, 223, 0.2);
$tile-carousel-dot-border-color: #9c9c9c;
$tile-carousel-dot-active-background: #dadada;
$tile-custom-badge-color: #414141;
$flyout-refinement-border-color: #ddd;

$store-locator-select-border-color: #848878;
$store-locator-select-selected-background-color: #f5f5f5;
$store-locator-select-pipe-background-color: #989898;
$store-locator-stores-divider-color: #b7b7b7;

$header-search-border-color: #cecece;
$header-search-input-placeholder-color: $clr_dark_mid;
$qv-start-price-color: #0f0f0f;
$swatches-selected-swatches-error-message-color: $cardinal;
$swatches-request-success-message-color: $goblin;

$pdp-cc-product-added-message-color: $goblin;

// fonts

// Mixins: Font styles for new PDP
/*  To utilize the font mixin,

@mixin font-source-serif($size: false, $color: false, $weight: false,  $lh: false, $ls: false) {
@mixin font-source-serif-italic($size: false, $color: false, $weight: false,  $lh: false, $ls: false) {
@mixin font-source-sans($size: false, $color: false, $weight: false,  $lh: false, $ls: false) {

// @include font-source-serif(40px, $brand-v2-off-black, normal, 50px, null);
// @include font-source-sans(16px, $brand-v2-off-black, normal, 21px, 0.03em);

*/

@mixin font-source-serif($size: false, $color: false, $weight: false,  $lh: false, $ls: false) {
    font-family: "ClassicalGaramondBT-Roman", serif;
    @if $size {
        font-size: $size;
    }
    @if $color {
        color: $color;
    }
    @if $weight {
        font-weight: $weight;
    }
    @if $lh {
        line-height: $lh;
    }
    @if $ls {
        letter-spacing: $ls;
    }
}

@mixin font-source-serif-italic($size: false, $color: false, $weight: false,  $lh: false, $ls: false) {
    font-family: "ClassicalGaramondBT-Italic", serif;
    @if $size {
        font-size: $size;
    }
    @if $color {
        color: $color;
    }
    @if $weight {
        font-weight: $weight;
    }
    @if $lh {
        line-height: $lh;
    }
    @if $ls {
        letter-spacing: $ls;
    }
}

@mixin font-source-sans($size: false, $color: false, $weight: false,  $lh: false, $ls: false) {
    font-family: $f-primary;
    @if $size {
        font-size: $size;
    }
    @if $color {
        color: $color;
    }
    @if $weight {
        font-weight: $weight;
    }
    @if $lh {
        line-height: $lh;
    }
    @if $ls {
        letter-spacing: $ls;
    }
}

$sans-serif: Arial, sans-serif;
$sans-serif-alt: Lato, sans-serif;
$serif: Crete Round, serif;

// flag-icon-css
$flag-icon-css-path: "../lib/flag-icon-css/flags";

// global classes
.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
}

.display-none {
    display: none;
}

.menu {
    float: left;
    margin: 0;
    padding: 0;
    li {
        float: left;
        list-style: none outside none !important;
    }
}

.text-ellipses {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 99%;
}

